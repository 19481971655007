[color='0'],
[color='error'],
.zui-color-fg-error {
	color: var(--zui-color-error);
}

[color='1'],
[color='warning'],
.zui-color-fg-warning {
	color: var(--zui-color-warning);
}

[color='2'],
[color='success'],
.zui-color-fg-success {
	color: var(--zui-color-success);
}

[color='3'],
[color='info'],
.zui-color-fg-info {
	color: var(--zui-color-sc-b-110);
}

[color='4'],
[color='grey'],
.zui-color-fg-grey {
	color: var(--zui-color-help);
}

[color='5'],
[color='white'],
.zui-color-fg-white {
	color: var(--zui-color-gs-10);
}

.zui-color-fg-text {
	color: var(--zui-color-text-default);
}

.zui-color-bg-info {
	background-color: var(--zui-color-info);
}

.zui-color-bg-success {
	background-color: var(--zui-color-success);
}

.zui-color-bg-warning {
	background-color: var(--zui-color-warning);
}

.zui-color-bg-error {
	background-color: var(--zui-color-error);
}

.zui-color-bg-grey {
	background-color: var(--zui-color-help);
}

.zui-color-bg-white {
	background-color: var(--zui-color-gs-10);
}

zui-textfield,
zui-comment {
	width: 100%;
}

zui-interactive-icon[emphasis='highlight'] {
	color: var(--zui-color-info);
}

zui-interactive-icon[disabled] {
	color: var(--zui-color-grey);
}

zui-interactive-icon:hover:not([disabled]) {
	opacity: var(--zui-disabled-opacity);
}

zui-section-divider[sticky] {
	position: sticky;
	top: 0;
}

zui-section-divider {
	margin-bottom: calc(var(--zui-gu) * 4);

	&:not(:first-child) {
		margin-top: calc(var(--zui-gu) * 1);
	}
}

zui-select {
	--zui-select-menu-elevation: 3;
}

zui-pill {
	white-space: pre;
}

zui-searchbar-input {
	max-width: 100%;
	width: 100%;
	margin-bottom: calc(var(--zui-gu) * 2);
}

zui-interactive-icon[inline] {
	display: inline-block;
	vertical-align: middle;
	line-height: inherit;
	margin-right: calc(var(--zui-gu) * 0.5);
}

zui-ewiq-dialog {
	max-width: 80vw;
	// TODO: this is a zui bug
	border: none;
}

zui-button {
	max-width: 300px;
	min-width: 80px;
}

zui-inline-message {
	min-width: 150px;
	width: 100%;
	max-width: 600px;
}

// zui-checkbox {
// 	align-items: center;
// 	display: flex;
// }

zui-toggle-bar-button {
	width: 75px;
}

zui-progress-ring {
	overflow: visible;
}
