:root {
	--vis-layout-padding: calc(var(--zui-gu) * 3);
}

[vis-container] {
	padding: calc(var(--zui-gu) * 0.5);
}

[vis-layout] {
	width: inherit;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	background-color: var(--zui-color-gs-10);

	column-gap: calc(var(--zui-gu) * 3);
	row-gap: calc(var(--zui-gu) * 3);

	&[vis-layout~='small-gap'] {
		grid-gap: calc(var(--zui-gu) * 1);
	}

	&[vis-layout~='medium-gap'] {
		grid-gap: calc(var(--zui-gu) * 2.5);
	}

	&[vis-layout~='large-gap'] {
		grid-gap: calc(var(--zui-gu) * 8);
	}

	&[vis-layout~='primary'] {
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	&[vis-layout~='padding'] {
		padding: var(--vis-layout-padding);
	}

	&[vis-layout~='flex'] {
		width: auto;
		height: auto;

		&:not([vis-layout~='dense']) > *:not(zui-button) {
			flex: 1;
		}
	}

	&[vis-layout~='grid'] {
		display: grid;
		grid-auto-rows: max-content;
	}

	&[vis-layout~='horizontal'] {
		> *:not(zui-button) {
			height: auto;
			overflow: auto;
			max-height: 100%;
			// Only for small screens like smartphone
			flex-direction: column;
		}
	}

	@media only screen and (min-width: 768px) {
		&[vis-layout~='horizontal'] {
			> *:not(zui-button) {
				flex: 1;
			}
		}

		[vis-layout] {
			row-gap: calc(var(--zui-gu) * 6);
		}
	}

	&[vis-layout~='content'] {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: 100%;

		> * {
			overflow: auto;
		}
	}

	&[vis-layout~='header-content'] {
		display: grid;
		grid-template-rows: 100px calc(100% - 100px - var(--zui-gu));

		> :nth-child(2) {
			overflow: auto;
		}
	}

	&[vis-layout~='header-content-footer'] {
		display: grid;
		grid-template-rows: 100px calc(100% - 200px - (var(--zui-gu) * 2)) 100px;

		> :nth-child(2) {
			overflow: auto;
		}
	}

	&[vis-layout~='content-footer'] {
		display: grid;
		grid-template-rows: calc(100% - 100px - var(--zui-gu)) 100px;

		> :nth-child(1) {
			overflow: auto;
		}
	}

	&[vis-layout~='vertical'] {
		flex-flow: column;
	}

	&[vis-layout~='centered'] {
		justify-content: center;
		align-items: center;
	}

	&[vis-layout~='end'] {
		justify-content: flex-end;
		align-items: flex-end;
	}

	&[vis-layout~='auto'] {
		height: auto !important;
		max-height: 100%;

		> * {
			height: auto;
		}

		&[vis-layout~='small'] {
			> * {
				width: 250px;
			}

			&[vis-layout~='fixed'] {
				> * {
					height: 200px;
				}
			}
		}

		&[vis-layout~='medium'] {
			> * {
				width: 400px;
			}

			&[vis-layout~='fixed'] {
				> * {
					height: 250px;
				}
			}
		}

		&[vis-layout~='large'] {
			> * {
				width: 600px;
			}

			&[vis-layout~='fixed'] {
				> * {
					height: 400px;
				}
			}
		}
	}
}

vis-box {
	display: flex;
	width: 100%;
}

.mat-form-field {
	&.width-xs {
		min-width: 50px;
	}

	&.width-s {
		min-width: 75px;
	}

	&.width-m {
		min-width: 100px;
	}

	&.width-l {
		min-width: 180px;
	}

	&.width-xl,
	&.width-xxl {
		width: 100%;
	}
}

@media only screen and (min-width: 768px) {
	.mat-form-field {
		&.width-xs {
			min-width: 50px;
		}

		&.width-s {
			min-width: 75px;
		}

		&.width-m {
			min-width: 100px;
		}

		&.width-l {
			min-width: 180px;
		}

		&.width-xl {
			min-width: 250px;
		}

		&.width-xxl {
			min-width: 350px;
		}
	}
}
