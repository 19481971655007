:root {
	// Customizing font sizes
	--zui-font-family: 'Frutiger Next', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', 'sans-serif' !important;

	// --zui-h0-font-size: 46px;
	// --zui-h0-line-height: 1.56521739;
	--zui-h0-font-size: 58px !important;
	--zui-h0-line-height: 1.56521739 !important;

	// --zui-h1-font-size: 31px;
	// --zui-h1-line-height: 1.5483871;
	--zui-h1-font-size: 36px !important;
	--zui-h1-line-height: 1.5483871 !important;

	// --zui-h2-font-size: 22px;
	// --zui-h2-line-height: 1.63636364;
	--zui-h2-font-size: 36px !important;
	--zui-h2-line-height: 1.63636364 !important;

	// --zui-h3-font-size: 19px;
	// --zui-h3-line-height: 1.47368421;
	--zui-h3-font-size: 28px !important;
	--zui-h3-line-height: 1.47368421 !important;
	--zui-h3-font-weight: 500 !important;

	// --zui-h4-font-size: 15px;
	// --zui-h4-line-height: 1.6;
	--zui-h4-font-size: 22px !important;
	--zui-h4-line-height: 1.6 !important;

	// --zui-subtitle1-font-size: 13px;
	// --zui-subtitle1-line-height: 1.53846154;
	--zui-subtitle1-font-size: 16px !important;
	--zui-subtitle1-line-height: 1.53846154 !important;
	--zui-subtitle1-font-weight: bold !important;

	// --zui-subtitle2-font-size: 11px;
	// --zui-subtitle2-line-height: 1.45454545;
	--zui-subtitle2-font-size: 11px !important;
	--zui-subtitle2-line-height: 1.45454545 !important;

	// --zui-body-font-size: 13px;
	// --zui-body-line-height: 1.53846154;
	--zui-body-font-size: 16px !important;
	--zui-body-line-height: 1.53846154 !important;

	// --zui-caption-font-size: 11px;
	// --zui-caption-line-height: 1.45454545;
	--zui-caption-font-size: 14px !important;
	--zui-caption-line-height: 1.45454545 !important;

	// --zui-label1-font-size: 11px;
	// --zui-label1-line-height: 1.45454545;
	--zui-label1-font-size: 16px !important;
	--zui-label1-line-height: 1.45454545 !important;

	// --zui-label2-font-size: 10px;
	// --zui-label2-line-height: 1.2;
	--zui-label2-font-size: 12px !important;
	--zui-label2-line-height: 1.2 !important;

	// Code font style
	--zui-code-font-style: normal;
	--zui-code-font-weight: normal;
	--zui-code-font-line-height: 1.2;

	--zui-typography-code: var(--zui-code-font-style) var(--zui-code-font-weight)
		var(--zui-code-font-size) / var(--zui-code-font-line-height) monospace;

	// Make this variable globally available to use in custom headerbar
	// TODO: Remove this once zui-headerbar is available
	--zui-menubar-default-width: 64px;

	// Icon sizes
	// TODO: Remove this hardcoded stuff (Currently these ZUi variables are not accessible from outside the ZUi icons) ;-(
	--zui-size-icon-xs: var(--zui-gu);
	--zui-size-icon-s: calc(var(--zui-gu) * 1.5);
	--zui-size-icon-m: calc(var(--zui-gu) * 2);
	--zui-size-icon-l: calc(var(--zui-gu) * 3);
	--zui-size-icon-xl: calc(var(--zui-gu) * 4);
	--zui-size-icon-2xl: calc(var(--zui-gu) * 6);
	--zui-size-icon-3xl: calc(var(--zui-gu) * 8);
	--zui-size-icon-4xl: calc(var(--zui-gu) * 10);

	--zui-color-text-selection-input: var(--zui-color-info) !important;

	// Make top border of EWIQ dialog "question" blue instead of grey
	--zui-color-ewi-question: var(--zui-color-info) !important;

	--vis-overlay-box-shadow: 0 0 0 9900px #00000047;
}

h0 {
	font: var(--zui-typography-h0);
}

h1 {
	font: var(--zui-typography-h1);
}

h2 {
	font: var(--zui-typography-h2);
}

h3 {
	font: var(--zui-typography-h3);
}

h4 {
	font: var(--zui-typography-h4);
}

// TODO: Remove this once --zui-bgc-content-wrapper variable is part of ZUi package
[theme='light'] {
	--zui-bgc-content-wrapper: var(--zui-color-gs-35);
}

zui-button,
zui-toggle-bar-button {
	--zui-typography-subtitle1: var(--zui-typography-body);
}
